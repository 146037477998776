import jQuery from "jquery"
window.$ = window.jQuery = jQuery
import '../styles/dashboard/entry.scss';
// import swiper from "../javascript/vendor/swiper"
require("bootstrap")

import * as Fontawesome from "../javascript/vendor/fontawesome"
require.context("../images", true, /\.(png|jpg|jpeg|svg)$/)
import * as Dropify from "../javascript/vendor/dropify"


// Toastrの設定
import toastr from 'toastr';
toastr.options = {
  closeButton: true,
  timeout: 2000,
  preventDuplicates: true,
};
global.toastr = toastr;
require("../styles/dropify.scss")

require.context("../images", true, /\.(png|jpg|jpeg|svg)$/)

$(function () {
  // swiper()
  // Fontawesome.iconToSvg()
  Dropify.activate({
    messages: {
      default:
        '<p class="dropify-text">ここに写真をドロップ<br>または</p><p class="btn-dropify">写真を選択<p>',
      replace: '<p class="dropify-text">ここに写真をドロップ<br>または</p>',
      remove: "削除",
      error: "Error",
    },
  })
})
