import { dom, library } from "@fortawesome/fontawesome-svg-core"
import {
  faAngleLeft,
  faAngleRight,
  faEnvelope,
  faFolderOpen,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons"
import {
  faInstagram,
  faTwitter,
  faFacebookF,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons"
library.add(faAngleLeft, faAngleRight, faEnvelope, faFolderOpen, faCheckCircle)
library.add(faInstagram, faFacebookF, faTwitter, faGoogle)

export const iconToSvg = () => {
  dom.i2svg()
}
